import axios from 'axios';
import Ready from '@/utils/ready';
import {uniq} from 'lodash';

const selector = '[data-ref="map"]';

type Shape = {
  id: number;
  regions: string[];
}

type Product = {
  id: number;
  regions: string[];
}

(() => {
  Ready.watch(selector, (element: any) => {
    (window as any).distributors = {
      regions: [],
      shapes: [],
      products: [],
    };
    const map: HTMLElement = element.querySelector('[data-ref="distributors-worldmap"]');
    const api: string = element.dataset.api;
    const target: any = document.querySelector('[data-ref="distributors-injection"]');

    (window as any).emitter.on('distributors-select-region', (region: string) => setRegion(region));

    const sendData = () => {
      const { shapes, products, regions } = (window as any).distributors;
      axios
        .get(`${api}`, {
          params: {
            shapes: shapes.map((s: any) => s.id).join(','),
            products: products.map((p: any) => p.id).join(','),
            regions: regions.join(','),
          },
        })
        .then((data: any) => {
          const responseDomTarget = new DOMParser().parseFromString(
            data.data,
            'text/html',
          );
          const domTarget = responseDomTarget.querySelector(
            '[data-dom-target]',
          );
          target.innerHTML = '';
          target.append(domTarget);
        });
    };
  
    const setShapes = (shapes: Shape[]) => {
      (window as any).distributors.shapes = shapes;
      highlightRegions();
      sendData();
    };

    const setProducts = (products: Product[]) => {
      (window as any).distributors.products = products;
      highlightRegions();
      sendData();
    };

    const setRegion = (region: string) => {
      const choicesInstance = (document.querySelector('[data-ref="distributor-select-regions"]') as any).choices;

      if (!(window as any).distributors.regions.includes(region)) {
        (window as any).distributors.regions = [
          ...(window as any).distributors.regions,
          region,
        ];
        choicesInstance.setChoiceByValue(region);
      } else {
        const filteredRegions = (window as any).distributors.regions.filter(
          (regionSlug: string) => regionSlug !== region,
        );

        (window as any).distributors.regions = filteredRegions;
        choicesInstance.removeActiveItemsByValue(region);
      }

      selectRegions();
      sendData()
    };

    const setRegions = (ids: number[]) => {
      (window as any).distributors.regions = ids;
      selectRegions();
      sendData();
    };

    const selectRegions = () => {
      const { regions } = (window as any).distributors;
      [...map.querySelectorAll('[data-region]')].forEach(
        (region: any) => {
          if (regions.includes(region.dataset.region)) {
            region.classList.add('selected');
          } else {
            region.classList.remove('selected');
          }
        },
      );
    };

    const highlightRegions = () => {
      const { shapes, products } = (window as any).distributors;

      const regions = uniq([
        ...shapes.map((o: any) => o.regions).flat(),
        ...products.map((o: any) => o.regions).flat(),
      ]);

      [...map.querySelectorAll('[data-region]')].forEach(
        (region: any) => {
          if (regions.includes(region.dataset.region)) {
            region.classList.add('highlighted');
          } else {
            region.classList.remove('highlighted'); 
          }
        },
      );
    }

    (window as any).distributors = {
      ...(window as any).distributors,
      setShapes,
      setRegion,
      setRegions,
      setProducts,
      highlightRegions
    }
  });
})();
