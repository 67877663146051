if (!!window.MSInputMethodContext && !!(document as any).documentMode) {
  let imports = [
    '@/polyfills/Append',
    '@/polyfills/Push',
    '@/polyfills/Stickyfill',
    '@/polyfills/Dataset',
    '@/polyfills/WeakMap',
    '@/polyfills/MutationObserver',
    '@/polyfills/ObjectFit',
    '@/polyfills/CustomProperties.min',
    '@/polyfills/InsertAdjacentHtml',
    '@/polyfills/isNaN',
    '@/polyfills/FindIndex',
    'alpinejs/dist/alpine-ie11',
  ];
  /* Add polyfills from the polyfills folder (order matters) */
  imports.forEach((module: string) => {
    import(module);
  });
} else {
  // @ts-ignore
  import('alpinejs').then((module) => {
    console.log(module);
    let cookie = document.querySelector('#cookie');

    if (cookie) {
      cookie.classList.remove('hidden');
    }
  });
}

// import { AppBus } from '@/plugins/event-bus';
// (window as any).AppBus = AppBus;

var ElementQueries = require('css-element-queries/src/ElementQueries');

ElementQueries.listen();
ElementQueries.init();

// (window as any).AppBus = AppBus;
/* Add scrollbar-width variable for accurate calculations with calc */
document.documentElement.style.setProperty(
  '--scrollbar-width',
  window.innerWidth - document.documentElement.clientWidth + 'px',
);

/* Import Pristine Config (for HRM)  */
import '@config/pristine.config.js';

// /** Unicons: https://iconscout.com/unicons */
import '@iconscout/unicons/css/solid.css';
import '@iconscout/unicons/css/line.css';

import mitt from 'mitt';

const emitter = mitt();

(window as any).emitter = emitter;

/* Auto Imports */
const srcContext = require.context('@', true, /.(runtime|asset|style).(.*?)$/);
srcContext.keys().forEach(srcContext);

const templatesContext = require.context(
  '@root/templates',
  true,
  /.(runtime|asset|style).(.*?)$/,
);

templatesContext.keys().forEach(templatesContext);

/* Import Styles */
import '@/styles/main.scss';

import '@/styles/mixins/ckcustom.scss';
