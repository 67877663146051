//comment
export const getNavigationOffset = () => {
  return ['.navigation']
    .map((selector) => {
      const el: HTMLElement | null = document.querySelector(selector);
      return el ? el.offsetHeight : 0;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue);
};

export const getUrlParamByName = (
  name: string,
  url: string = window.location.href,
) => {
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};