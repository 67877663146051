import Ready from '@/utils/ready';

const selector = '[data-ref="svg-line"]';
const screens = [1400, 1200, 992, 768, 576];

(() => {
  Ready.watch(selector, (element: any) => {
    let svg: any = element.querySelector('svg');
    let screenSize: number = element.offsetWidth;
    let bp: any = screens.find((size) => screenSize > size);
    let color: string = element.dataset.color
      ? element.dataset.color
      : '#EBEBEB';
    if (bp) {
      let border = (screenSize - bp) / 2;
      svg.insertAdjacentHTML(
        'beforeend',
        `<line x1="${border}" y1="0" x2="${border}" y2="100%" stroke="${color}" stroke-width="1" />`,
      );
      [1, 2, 3].forEach((number: number) => {
        svg.insertAdjacentHTML(
          'beforeend',
          `<line x1="${border + (bp / 3) * number}" y1="0" x2="${
            border + (bp / 3) * number
          }" y2="100%" stroke="${color}" stroke-width="1" />`,
        );
      });
    }
  });
})();
