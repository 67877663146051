import Ready from '@/utils/ready';
import Punchline from './punchline';

const selector = '[data-ref="punchline"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/punchline').then((Punchline: any) => {
      new Punchline.default(element);
    });
  });
})();
