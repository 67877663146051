import axios from 'axios';
import AOS from 'aos';
import {setFlashMessageSpacing} from '@/plugins/plugins';
import Scroller from '@/plugins/scroller';

let scroller = new Scroller();

let Stickyfill = require('@/polyfills/Stickyfill');

AOS.init({
  once: false,
});

(window as any).axios = axios;

(window as any).Scroller = scroller;

(window as any).setFlashMessageSpacing = setFlashMessageSpacing;

(window as any).captchaCallback = (token: any) => {
  if ((window as any).currentCaptcha) {
    (window as any).currentCaptcha.setAttribute('data-checked', '');
  }
};

let sectionBar = document.querySelector("[data-ref*='sticky']");

if (sectionBar) {
  Stickyfill.add(sectionBar);
}
