import Ready from '@/utils/ready';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const selector = '[data-tippy]';

(() => {
  Ready.watch(selector, (element: any) => {
    const content = document.querySelector(`[data-tippy-content="${element.dataset.tippy}"]`)
    if (element && content) {
      tippy(element, {
        content,
      });
    }
  });
})();
