import Ready from '@/utils/ready';

const selector = '[data-input-number]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/input-number').then((InputNumber: any) => {
      new InputNumber.default(element);
    });
  });
})();
