import Ready from '@/utils/ready';

const selector = '[data-input-change]';

(() => {
  Ready.watch(selector, (element: HTMLInputElement) => {
    import('@/module/inputChangeEmitter').then((InputNumber: any) => {
      new InputNumber.default(element);
    });
  });
})();
