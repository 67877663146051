import Ready from '@/utils/ready';

const selector = '[data-ref="dialog"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/dialog').then((Dialog: any) => {
      new Dialog.default(element);
    });
  });
})();
