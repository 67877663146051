import Ready from '@/utils/ready';

const selector = '[data-autocomplete]';

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
       import('@/module/autocomplete-input').then((AutocompleteInput: any) => {
           new AutocompleteInput.default(element);
       })
    });
})();
