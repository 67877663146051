import Ready from '@/utils/ready';

const selector = '[data-ref="slider"]';

(() => {
  (window as any).slideNextAndUpdate = (el: any) => {
    let sliderEl = el.closest('.swiper-container');
    if (sliderEl && sliderEl.swiper) {
      sliderEl.swiper.update();
      sliderEl.swiper.slideNext();
    }
  };

  (window as any).slidePrevAndUpdate = (el: any) => {
    let sliderEl = el.closest('.swiper-container');
    if (sliderEl && sliderEl.swiper) {
      sliderEl.swiper.update();
      sliderEl.swiper.slidePrev();
    }
  };

  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/slider').then((Slider: any) => {
      new Slider.default(element);
    });
  });
})();
