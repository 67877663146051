import Ready from '@/utils/ready';

const selector = '[data-input-file]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/input-file').then((InputFile: any) => {
      new InputFile.default(element);
    });
  });
})();
