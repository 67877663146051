import Ready from '@/utils/ready';

const selector = '[data-ref="history"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/history').then((History: any) => {
      new History.default(element);
    });
  });
})();
