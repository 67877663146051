const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1400px',
};

const pristineConfig = {
  screens: {
    xs: '560px',
    ...screens,
    xl3: '1600px',
  },
  bootstrapBreakpoints: {
    xs: 0,
    ...screens,
    xl3: '1600px',
  },
  bootstrapMaxWidths: screens,
  gutters: {
    base: '15px',
    lg: '30px',
    xl: '70px',
  },
  colors: {
    primary: '#C63924',
    'primary-dark': '#8A2819',
    secondary: '#4694A0',
    tertiary: {
      lightest: '#EBEBEB',
      lighten: '#E0E0E0',
      light: '#D6D6D6',
      base: '#C4C4C4',
    },
    black: {
      lightest: '#d6d6d6',
      light: '#545454cc',
      base: '#262626',
      medium: '#404040',
    },
    beige: {
      base: '#EDEBD7',
      dark: '#EDE0D7',
    },
    grey: '#545454cc',
    'grey-light': '#e0e0e0',
    'grey-lighten': '#F4F4F4',
  },
  unit: {
    base: '40px',
  },
  spacing: {
    xl2: '100px',
    xxl: '60px',
    xl: '40px',
    lg: '32px',
    md: '26px',
    base: '20px',
    sm: '14px',
    xs: '9px',
    xxs: '5px',
    gutter: '15px',
  },
  fontSize: {
    xxl: '90px',
    xl: '60px',
    lg: '32px',
    md: '29px',
    sm: '24px',
    xs: '21px',
    '2xs': '16px',
    xxs: '13px',
    '2xxs': '10px',
    base: '18px',
  },
  letterSpacing: {
    md: '0.5px',
    base: '-0.5px',
    sm: '-1px',
    xs: '-1.5px',
    xxs: '-5px',
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {
    base: '0.5rem 0.625rem 1.25rem rgba(38, 38, 38, 0.5)',
  },
  borderRadius: {
    base: '20px',
    curved: '20px 0',
    curvedTop: '20px 20px 0 0',
    curvedTopLeft: '20px 0 0 0',
    curvedBottomRight: '0 0 20px 0',
    curvedBottomLeft: '0 0 0 20px',
    curvedRight: '0 20px 20px 0',
    curvedLeft: '20px 0 0 20px',
  },
  borderWidth: {},
  boxShadow: {
    base: '2px 2px 10px 5px rgba(0, 0, 0, 0.2)',
    darken: '3px 3px 16px 4px rgba(38,38,38,0.31)',
  },
  container: {},
  cursor: {},
  fill: {},
  flex: {
    '2': '2 2 0%',
  },
  flexGrow: {},
  flexShrink: {},
  fontFamily: {},
  fontWeight: {},
  height: {
    initial: 'initial',
  },
  inset: {
    '1/2': '50%',
  },
  lineHeight: {},
  listStyleType: {},
  margin: {},
  maxHeight: {},
  maxWidth: {
    'lg': '400px'
  },
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  width: {},
  zIndex: {},
};

module.exports = pristineConfig;
