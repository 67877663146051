import Ready from '@/utils/ready';
import axios from 'axios';

const selector = '[data-ref="search"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    let api: string =
      element.dataset && element.dataset.api ? element.dataset.api : '';
    let error: string =
      element.dataset && element.dataset.error ? element.dataset.error : '';
    let input = element.querySelector('[data-input]');
    let target: any = element.querySelector('[data-target]');
    let timer: number = 0;

    if (api) {
      (input as any).addEventListener('input', (e: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          element.classList.add('loading');
          target.classList.add('loading');
          axios
            .get(api, {
              params: {
                research: e.target.value,
              },
            })
            .then((res) => {
              target.classList.remove('loading');
              element.classList.remove('loading');
              target.innerHTML = res.data;
            })
            .catch(() => {
              target.innerHTML = `<div class="w-full">
                <p class="text-center text-white text-md">${error}</p>      
              </div>`;
              target.classList.remove('loading');
              element.classList.remove('loading');
            });
        }, 500);
      });
    }
    // import('@/module/count-up').then((countUp: any) => {
    //     new countUp.default(element);
    // });
  });
})();
