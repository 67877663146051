import Ready from '@/utils/ready';

const selector = '[data-ref="form-errors"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/form-errors').then((FormErrors: any) => {
      new FormErrors.default(element);
    });
  });
})();
