import Ready from '@/utils/ready';

const selector = '[data-ref="count-up"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/count-up').then((countUp: any) => {
      new countUp.default(element);
    });
  });
})();
