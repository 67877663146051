import Ready from '@/utils/ready';

const selector = '[data-ref*="sectionBar"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/section-bar').then((SectionBar: any) => {
      new SectionBar.default(element);
    });
  });
})();
