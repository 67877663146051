import Ready from '@/utils/ready';
import {
  d3,
  partition,
  arc as Arc,
  mousearc as Mousearc,
  color,
  getParent,
  ancestors,
} from '@/plugins/d3-utils';
const selector = '[data-ref="sunburst"]';

(() => {
  Ready.watch(selector, (sunburst: any) => {
    const title = sunburst.querySelector('[data-title]');
    const isResponsive = window.innerWidth < 768;
    const squareSize = sunburst.clientWidth;
    const squareRadius = squareSize / 2;
    const arc = Arc(squareRadius);
    const mousearc = Mousearc(squareRadius);
    const root = partition(JSON.parse(sunburst.dataset.data), squareRadius);
    const svg = d3.create('svg');
    const element = svg.node();

    const checkMendeleiv = (params: any = null) => {
      // if (!isResponsive) {
      const mendeleiev = document.querySelector('[data-ref="mendeleiev"]');
      const mendeleievElements: any = Array.from(
        (mendeleiev as any).querySelectorAll('[data-id]'),
      );

      mendeleievElements.forEach((mendeleievElement: any) => {
        if (params && params.family && params.subfamily) {
          if (params.family.includes(mendeleievElement.dataset.id)) {
            mendeleievElement.classList.add('family');
          } else {
            mendeleievElement.classList.remove('family');
          }

          if (params.subfamily.includes(mendeleievElement.dataset.id)) {
            mendeleievElement.classList.add('subfamily');
          } else {
            mendeleievElement.classList.remove('subfamily');
          }
        } else {
          mendeleievElement.classList.remove('subfamily');
          mendeleievElement.classList.remove('family');
        }
      });
    };

    element.value = { sequence: [] };

    svg
      .attr(
        'viewBox',
        `
          ${-squareRadius} 
          ${-squareRadius} 
          ${squareSize} 
          ${squareSize}
        `,
      )
      .style('max-width', `${squareSize}px`)
      .style('width', `${squareSize}px`)
      .style('height', `${squareSize}px`)
      .style('font', '12px sans-serif')
      .style('height', squareSize);

    const path = svg
      .append('g')
      .on('mouseout', () => {
        if (!isResponsive) {
          path.attr('fill-opacity', 1);
          title.innerHTML = title.dataset.title;
          element.value = { sequence: [] };
          element.dispatchEvent(new CustomEvent('input'));
          checkMendeleiv();
        }
      })
      .selectAll('path')
      .data(
        root.descendants().filter((d: any) => {
          return d.depth && d.x1 - d.x0 > 0.001;
        }),
      )
      .enter()
      .append('a')
      .attr('href', (d: any) => {
        if (window.innerWidth > 1200) {
          return d.data.router;
        }
        return null;
      })
      .on('mouseover', (e: any) => {
        if (window.innerWidth > 1200) {
          checkMendeleiv(e.data.elements);
        }
        const sequence = ancestors.bind(e)().reverse().slice(1);
        path.attr('fill-opacity', (node: any) =>
          sequence.indexOf(node) >= 0 ? 1.0 : 0.3,
        );
        if (window.innerWidth > 1200) {
          title.innerHTML = e.data.name;
        } else {
          title.innerHTML = `
            <span class="flex flex-col">
                <span>${e.data.name}</span>
                <a href="${
                  e.data.router
                }"><button class="mt-xxs button button--sm button--curved">${
            (window as any).translations.misc.see
          }</button></a>
            </span>`;
        }
        // title.setAttribute('href', e.data.router);
        element.value = { sequence };
        element.dispatchEvent(new CustomEvent('input'));
      })
      .append('path')
      .attr('fill', (d: any) => {
        let parent: any = getParent(d);
        return parent.data.color;
      })
      .attr('opacity', (d: any) => {
        return 1 - 0.3 * (d.depth - 1);
      })
      .attr('d', arc);

    sunburst.append(svg._groups[0][0]);
  });
})();
