import Ready from '@/utils/ready';

const selector = '[data-load-container]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/data-loader').then((dataLoader: any) => {
      new dataLoader.default(element);
    });
  });
})();
