const setFlashMessageSpacing = (flashMessage: any) => {
    let globalFlashMessages = Array.from(
        document.querySelectorAll('.flashMessage.visible'),
    );
    let index = globalFlashMessages.findIndex((element: any) => {
        return element === flashMessage;
    });

    if (index >= 0) {
        return flashMessage.clientHeight * index;
    }
    return 0;
};

export {setFlashMessageSpacing};
