import Color from 'color';
// @ts-ignore
export const d3 = require('d3');

export const partition = (data: any, radius: number) => {
  return d3.partition().size([2 * Math.PI, radius * radius])(
    d3
      .hierarchy(data)
      .sum((d: any) => d.value)
      .sort((a: any, b: any) => b.value - a.value),
  );
};

export const arc = (radius: number) =>
  d3
    .arc()
    .startAngle((d: any) => d.x0)
    .endAngle((d: any) => d.x1)
    .padAngle(1 / radius)
    .padRadius(radius)
    .innerRadius((d: any) => Math.sqrt(d.y0))
    .outerRadius((d: any) => Math.sqrt(d.y1) - 1);

export const mousearc = (radius: number) =>
  d3
    .arc()
    .startAngle((d: any) => d.x0)
    .endAngle((d: any) => d.x1)
    .innerRadius((d: any) => Math.sqrt(d.y0))
    .outerRadius(radius);

export const color = (string: string = '#ffff00', depth: number): string => {
  let c = Color(string ? string : '#000');
  // let c = Color(string ? string : '#000').lighten(0.15 * (depth - 1));
  return c.hex();
};

export const getParent = (item: any): void => {
  if (item.depth !== 1) {
    return getParent(item.parent);
  }
  return item;
};

export function ancestors() {
  // @ts-ignore
  var node = this,
    nodes = [node];
  while ((node = node.parent)) {
    nodes.push(node);
  }
  return nodes;
}
