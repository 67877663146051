import Ready from '@/utils/ready';

const selector = '[data-select]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/choices').then((ChoicesModule: any) => {
      new ChoicesModule.default(element);
    });
  });
})();
