import Ready from '@/utils/ready';

const selector = '[data-ref="dataTable"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    import('@/module/data-table').then((DataTable: any) => {
      new DataTable.default(element);
    });
  });
})();
